.startupcontainertitle {
    align-self: stretch;
    position: relative;
    line-height: 30px;
    text-transform: uppercase;
    font-weight: 600;
  }
  .product-category {
    flex: 1;
    position: relative;
    line-height: 25px;
    text-transform: capitalize;
    font-weight: 500;
  }
  .product-category-wrapper {
    background-color: var(--black);
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--white);
  }
  
  .pole {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: stretch;
    z-index: 3;
  }

  .vector-icon{
    flex-shrink: 0;
    align-self: stretch;
  }

  .pole-points{
    margin-top: 20px;
    display: flex;
    gap: 0;
    flex-direction: row;
  }

  .startup-box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 80px;
    padding-bottom: 30px;
  }


  .pole,
  .pole-points,
  .startups-container {
    display: flex;
    align-items: flex-start;
  }
  .pole-points {
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-start;
  }
  .startups-container {
    position: relative;
    background-color: #ffe68b;
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    padding: 50px 30px;
    box-sizing: border-box;
    justify-content: center;
    gap: 40px;
    text-align: left;
    font-size: var(--font-size-5xl);
    color: var(--black);
    font-family: var(--font-poppins);
  }
  
  @media screen and (max-width:420px){
    .startupcontainertitle{
        font-size: 18px;
        line-height: 30px;
    }
    .product-category{
        font-size: 16px;
    }

    .startups-container{
        gap: 20px;
        padding: 20px 10px;
    }
  }