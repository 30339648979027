
  .mobile-mockup {
    width: 100%;
    /* height: 60%; */
    object-fit: cover;
    padding: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    height: auto;
  }


  .container-3{
    width: 85%;
    background-color: var(--white);
    padding: 2rem;
    border-radius: var(--br-base);
    box-shadow: var(--effect-1);
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 40px 0;
     
}

  .mobile-mockup {
    width: 100%;
    /* height: 60%; */
    object-fit: cover;
    padding: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
    margin-bottom: 20px;
    height: auto;
  }

  .logo-footer, .terms,.policy{
    cursor: pointer;
  }
  .mobile-mockup img{
    width: 15%;
    cursor: pointer;
    
  }
  .rhap-container{
    width: 100%;
  }
  /* .audio-player {
    width: 20%;
  } */
  /* .container-3 {
    border-radius: var(--br-base);
    background-color: var(--white);
    box-shadow: var(--effect-1);
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
    padding: 1.2rem;
    margin: 1.2rem;
  } */
  .bottom-left-bar,
  .bottom-right-bar,
  .top-left-bar,
  .top-right-bar {
    z-index: 2;
    width: 3%;
    background-color: var(--yellow);
    height: 20%
  }
  .bottom-left-bar{
    position: absolute;
    bottom: 0;
    left: 8%;
    border-radius: 100px 100px 0 0;
  }
  .top-left-bar{
    position: absolute;
    top: 0;
    left: 8%;
    border-radius: 0 0 100px 100px;
  }
  .top-right-bar{
    position: absolute;
    top: 0;
    right: 8%;
    border-radius: 0 0 100px 100px;
  }
  .bottom-right-bar{
    position: absolute;
    bottom: 0;
    right: 8%;
    border-radius: 100px 100px 0 0;
  }

  .feature-3 {
    background-color: var(--white);
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    position: relative;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .bg{
    position: relative;
    width: 100%;
  }

/* Add these classes to your Feature3.css */



.image {
  transition: transform 0.3s;
  cursor: pointer;
}

.image.active {
  transform: translateY(-40px); /* Adjust the value based on your layout */
  z-index: 1;
}


@keyframes danceAnimation {
  0% {
    transform: translateY(0);
    box-shadow: none;
  }
  100% {
    transform: translateY(-20px);
    /* box-shadow: 0px 10px 20px; */
  }
  /* 100% {
    transform: translateY(0);
    box-shadow: none;
  } */
}
/* .clicked-image {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Customize the shadow as needed */



.dance {
  animation: danceAnimation 1s ease-in-out;
}

  @media screen and (max-width: 912px){

    .mobile-mockup {
        width: 100%;
        padding: 0;
        margin: 30px 0;
        height: 40%;
      }
      .audio-player {
        width: 100%;
      }
      .container-3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        z-index: 0;
        padding: 2.4rem 1.4rem;
        margin: 3rem 0;
      }

  }

  @media screen and (max-width: 767px){
    .bottom-left-bar{
        left: 7.7%;
        
      }
      .top-left-bar{
        left: 7.7%;
      }
      .top-right-bar{
        right: 7.7%;
      }
      .bottom-right-bar{
        right: 7.7%;
      }
      .mobile-mockup {
        width: 100%;
        /* height: 60%; */
        object-fit: cover;
        padding: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin: 20px 0;
        height: auto;
      }
      .mobile-mockup img{
        width: 35%;
        cursor: pointer;
        
      }
      .container-3{
        width: 100%;
        padding: 3rem 2rem;
        gap: 20px;
      }
  }

  /* .rhap_container {
    background: #f7f7f9;
    height: 20% ;
  }
  
  .rhap_controls-section {
    margin-bottom: 15px;
  }
  
  .rhap_progress-section {
    height: 20px;
    width: 100%;
   
  }
  
  .rhap_main-controls-button {
    width: 80px !important;
    height: 80px !important;
  }
  
  .rhap_main-controls-button {
    width: 56px;
    height: 56px;
    display: block;
  }
  
  .rhap_main-controls-button svg {
    color: var(--orange);
    width: 100%;
    height: 100%;
  }
  .rhap_progress-filled,
  .rhap_progress-indicator {
    background-color:var(--orange) !important;
  }
  
  .rhap_button-clear.rhap_volume-button {
    color: #ff5555 !important;
  }
  
  .rhap_volume-bar, .rhap_volume-indicator {
    background-color: var(--orange);
  } */