.beams {
    margin: 0;
    align-self: stretch;
    font-size: 72px;
    font-weight: 400;
    font-family: var(--bookerly-reading-desktop);

  }

  .hero-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
  }

  .subscribe-now1,
  .unleashing-the-light {
    position: relative;
    text-transform: capitalize;
  }
  .unleashing-the-light {
    margin: 0;
    align-self: stretch;
    font-size: var(--font-size-xl);
    font-family: var(--font-poppins);
    font-weight: normal;
  }
  .btn {
    font-family: var(--font-poppins);
    color: var(--white);
    text-align: center;
  }
  .primary-button1 {
    cursor: pointer;
    border: 0;
    padding: 10px 50px;
    background-color: var(--orange);
    border-radius: 100px;
    justify-content: center;
    font-size: 20px;
  }
  .content,
  .hero,
  .primary-button1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .content {
    justify-content: flex-start;
    gap: 25px;
    padding: 0 10px;
  }

  .content p{
    font-family: var(--font-mulish);
    font-size: 32px;
    padding: 0 30px;
  }
  .hero {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    justify-content: center;
    background-image: url(/public/Assets/images/hero-bg.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    text-align: center;
    font-size: 4rem;
    color: var(--black);
  }
  
  @media screen and (max-width: 600px){
    .unleashing-the-light {
      font-size: 18px;
      font-weight: 400;
      
    }
    .beams{
      font-size: 40px;
    }
    .content{
      gap: 40px;
    }
    .content p{
      /* font-family: var(--font-mulish); */
      font-size: 24px;
    }
  }

