.do-you-know1 {
    margin: 0;
    position: relative;
    font-size: var(--font-size-xl);
    text-transform: capitalize;
    font-weight: 400;
    font-family: inherit;
  }
  .section-title {
    border-radius: 16px;
    background-color: var(--black);
    display: flex;
    flex-direction: row;
    padding: var(--padding-mini) var(--padding-xl);
    align-items: center;
    justify-content: space-between;
  }
  .people-have-even {
    position: relative;
    font-size: var(--font-size-xl);
    line-height: 1.5;
    font-weight: 500;
    font-family: var(--font-mulish);
    color: var(--black);
    width: 50%;
  }
  .Goldfish{
    width: 40%;
    object-fit: cover;
    animation: swim 2s linear infinite;
  }

  @keyframes swim {
    0% {
      transform: translate(0, 0) rotate(0);
    }
    25% {
      transform: translate(0px, 0px) rotate(10deg);
    }
    50% {
      transform: translate(0, 0) rotate(0);
    }
    75% {
      transform: translate(0px, 0px) rotate(-10deg);
    }
  }
  .do-you-know {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    background-image: url(/public/Assets/images/do-you-know-bg1.webp);
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: top;
    text-align: center;
    font-size: 3rem;
    color: var(--white);
    font-family: var(--font-pacifico);
  }
  

  @media screen and (max-width: 600px) {
    .do-you-know1{
        font-size: var(--font-size-xl);
    }
    .people-have-even {
        width: 90%;
        font-size: var(--font-size-l);
    }
    .Goldfish{
        width: 100%;
        object-fit: cover;
    
      }
  }
  @media screen and (max-width: 750px) {
    .Goldfish{
        width: 70%;
        object-fit: cover;
    }
    
  }
  @media screen and (max-width: 992px) and (min-width : 768px)  {
    .do-you-know1{
        font-size: 3rem;
       padding: var(--padding-xl);
    }
    
    .people-have-even {
        width: 70%;
        font-size: var(--font-size-1xl)
        
    }
    .Goldfish{
        width: 70%;
        object-fit: cover;
    }
}