
  .data-cards-box {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
  flex-direction: row;
  text-align: center;
  font-size: var(--font-size-base);
  font-family: var(--bookerly-reading-desktop);
  width: 100%;
  }

@media screen and (max-width: 912px) {
    
    .data-cards-box {
      flex-direction: column;
    }
  }
  