
  .substrate-group {
    border-radius: var(--br-base);
    background-color: var(--black);
    display: flex;
    flex-direction: row;
    padding: var(--padding-mini) var(--padding-xl);
    align-items: center;
    justify-content: center;
  }
  .title {
    text-transform: capitalize;
    font-weight: 600;
    color: var(--black);
  }
  .number {
    text-transform: capitalize;
    font-weight: 600;
    font-size: var(--font-size-xl);
  }
  .feature-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
    text-align: center;
    font-size: var(--font-size-xl);
    color: var(--white);
    font-family: var(--font-poppins);
  }
  
  @media screen and (max-width: 600px) {
    .feature-title{
      flex-direction: column;
      font-size: var(--font-size-base);
      gap: 10px;
    }
    .number{
      font-size: var(--font-size-base);
    }
    .substrate-group {
      padding: 5px 10px;
      border-radius: var(--br-xs);
    
    }
  }
  @media screen and (max-width: 912px) {
    .feature-title{
      flex-direction: column;
      font-size: 20px;
      gap: 10px;
    }
    .number{
      font-size: var(--font-size-base);
    }
    .substrate-group {
      padding: 5px 10px;
      border-radius: var(--br-xs);
    
    }
  }