.start-content {
    align-self: stretch;
    position: relative;
    line-height: 35px;
    font-weight: 200;
  }
  .superscript-link2{
    color: var(--white) 0.5;
  }
  .start-box {
    border-radius: var(--br-7xs);
    background-color: var(--black);
    width: 700px;
    display: flex;
    flex-direction: column;
    padding: var(--padding-mini) var(--padding-xl);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--bookerly-reading-desktop-size);
    color: var(--white);
    font-family: var(--bookerly-reading-desktop-italic);
  }
  
  @media screen and (max-width: 420px) {
    .start-content {
      font-size: var(--font-size-base);
      line-height: 30px;
    }
  
    .start-box {
      align-self: stretch;
      width: auto;
    }
  }
  