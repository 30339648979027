.PopUpShowMore{
    width: 600px;
    max-height: 800px;
    /* overflow-y: auto; */
    background-color: white;
    border: 4px solid orange;
    border-radius: 20px;
    padding: 25px;
    position: relative;
}

.titlereadmorepopnotes{
   
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.readmorenotestitle{
    font-weight: 600;
    font-size: 26px;
}

.datesss{
    font-size: 20px;
    color: rgb(142, 142, 142);
}

.PopUpShowMore > p{
    font-size: 22px;
    margin-top: 40px;
}

.closer{
    cursor: pointer;
    position: absolute;
    top: -20px;
    right: -20px;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}