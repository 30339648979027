.footer{
    width: 100%;
    height: auto;
    padding: 2rem 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    flex-direction: column;
    margin-top: 20px;
    /* position: absolute;
    bottom: 0;
    left: 0; */
}

.footer-content{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: var(--font-size-xs);
    color: var(--black);
    font-weight: 200;
    font-family: var(--font-mulish);
    font-size: 16px;
    margin-bottom: 10px;
    gap: 0px;
}

.footer-content a{
    text-decoration: none;
    color: var(--black);
}
.logo-footer{
    /* background: url(/public/Assets/images/logo.webp); */
    background-size: cover;
    object-fit: cover;
    background-position: center;
    /* height: 25px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-footer img{
    width: 120px;
}

.logo-dummy{
    opacity: 0;
    height: 30px;
    display: block;
}

.newsletter{
    display: flex;
    align-items: center;
    justify-content: center;
}

.newsletter-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 100px;
    background-color: var(--white);
    border-radius: 50px;
    width: 75%;
    height:25vh;
    padding: 2rem 2.5rem;
    box-shadow: var(--effect-1);
    position: relative;
    z-index: 2;
}
.newsletter-title{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: auto;
    width: 50%;
}

.input-box{
    width: 50%;
    height: 8vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 100px;
    box-shadow: 10px 20px 50px 0px rgba(0, 0, 0, 0.15);
    z-index: 30;
    background-color: var(--white);
}

.email-input{
    padding-left: 20px;
    background: transparent;
    border: none;
    font-family: var(--font-mulish);
    font-size: var(--font-size-xs);
    z-index: 30;
}

.yellow-bg{
    position: absolute;
    background-color: var(--yellow);
    width: 35%;
    height: 20vh;
    top: 10%;
    right: 2%;
    z-index: -20;
    border-radius: 100px 60px 60px 100px;

}

.email-input:focus{
    border: none;
    outline: none;
    background: transparent;
}
.footer-btn{
    width: 40%;
    padding: 10px;
    border-radius: 100px;
    border: none;
    background-color: var(--orange);
    color: var(--white);
    font-family: var(--font-mulish);
    font-size: var(--font-size-base);
    z-index: 30;
    height: 100%;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.newsletter-title h1{
    font-family: var(--font-poppins);
    font-size: var(--font-size-xl);
    margin: 0;
}
.newsletter-title p{
    font-family: var(--font-mulish);
    font-size: 16px;
    margin: 0;

}

@media screen and (max-width: 912px){
    .newsletter-container{
        flex-direction: column;
        gap: 50px;
        justify-content: center;
        padding:9rem 2rem;
        height: 20vh;
        width: 90%;
    }
    .yellow-bg{
        display: none;
    }
    .newsletter-title{
        align-items: center;
    }
    .newsletter-title p{
        text-align: center;
    }
    .input-box{
        width: 75%;
        justify-content: space-between;
        height: 5vh;
    }
    .email-input{
        width: 60%;
        padding-left: 20px;
    }
    .logo-footer img{
        width: 100px;
    }
}
@media screen and (max-width: 912px){
    .footer-content{
        flex-direction: column;
        gap: 20px;
        font-size: var(--font-size-xs);
    }

    .newsletter-container{
        min-height: 30vh;
        gap: 40px;
        padding: 1rem 2rem;
        width: 60%;
        border-radius: 12px;

    
    }

    .logo-footer{
        height: 30px;
        width: auto;    
    }
    
    .footer{
        gap: 50px;
    }
    .newsletter-title{
        width: 100%;
    }

    .newsletter-title h1{
        font-size: var(--font-size-l);
    }
    .newsletter-title p{
        font-size:var(--font-size-xs) ;
    }

    .input-box{
        width: 100%;
        justify-content: space-between;
        
    }
      
    .footer-btn{
        font-size: var(--font-size-xs);
        width: 40%;
        height: 5vh;
        font-size: 18px;
    }
}
@media screen and (max-width: 767px){
    .footer-content{
        flex-direction: column;
        gap: 20px;
        font-size: var(--font-size-xs);
    }

    .newsletter-container{
        min-height: 30vh;
        gap: 40px;
        padding: 1rem 2rem;
        width: 90%;
        border-radius: 12px;

    
    }

        .logo-footer img{
            width: 100px;
        }   
        
    .footer{
        gap: 50px;
    }
    .newsletter-title{
        width: 100%;
    }

    .newsletter-title h1{
        font-size: var(--font-size-l);
    }
    .newsletter-title p{
        font-size:var(--font-size-xs) ;
    }

    .input-box{
        width: 100%;
        justify-content: space-between;
        
    }
      
    .footer-btn{
        font-size: var(--font-size-xs);
        width: 40%;
        height: 5vh;
    }
}



@media screen and (max-width: 280px){
    .newsletter-container{
        width: 100%;
        padding: 1rem 1rem;
    }
    .footer-btn{
        font-size: var(--font-size-xs);
        width: 40%;

    }
    .footer{
        padding: 1rem;
    }
    
}