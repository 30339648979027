.canva-embed{
    width: 100%;
    height: 600px;
    margin-top: 80px;
}

@media screen and (max-width:767px){
    .canva-embed{
        height: 400px;
    }
}

