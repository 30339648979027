.feature-2{
    width: 100%;
    background: url(/public/Assets/images/Feature-2-bg.webp);
    background-size: cover;
    background-position: center;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    border: none;
}

.container2{
    width: 85%;
    background-color: var(--white);
    padding: 2rem;
    border-radius: var(--br-base);
    box-shadow: var(--effect-1);
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
     
}

.feature-content2{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
}
.img-box1{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topicimg1{
    width: 70%;
}


.topics-container{
    width: 50%;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 70px;
    
}

.topic-card{
    background-color: var(--white);
    box-shadow: var(--effect-1);
    border-radius: var(--br-xs);
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 200px;
}

.topic-img{
    width: 200px;
    border-radius: var(--br-xs) var(--br-xs) 0 0;
    height: 110px;
  }

.topic-title{
    text-align: center;
    width: 100%;
    font-family: var(--font-mulish);
    font-size: var(--font-size-xs);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    padding: 8px 5px;

    height: 30%;
}

.topic-card a{
    color: var(--black);
}




@media screen and (max-width: 1200px){
    .feature-2{
        height: auto;
        background-position: center;
        padding: 1rem 0;
    }

    .rhap_controls-section{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .rhap_additional-controls, .rhap_volume-controls{
        display: none;
    }

    .topics-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 80px;
        height: auto;
        width: 100%;
    }
    .feature-content2{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 70px;
    }

    .container2{
        width: 80%;
        height: auto;
        background-color: var(--white);
        padding: 2.5rem 2rem ;
        /* margin: 2.5rem 1.2rem; */
        border-radius: var(--br-base);
        box-shadow: var(--effect-1);
        display: flex;
        flex-direction: column;
        gap: 30px;   
    }
    
    .img-box1{
        width: 100%;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    /* .topicimg1{
        width: 50%;
    } */

    .topic-card{
    width: 180;
        box-shadow: var(--effect-1);
        border-radius: var(--br-xs);
        display: flex;
        flex-direction: column;
        gap: 0px;
    
    }
    
    /* .topic-img{
        width: 100%;
        height: 65%;
    } */
    
    .topic-title{
        text-align: center;
        /* width: 100%; */
        height: auto;
        font-family: var(--font-mulish);
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 912px){

    .feature-content2{
        gap: 0px;
    }
    .topicimg1{
        display: none;
    }
    
    /* .topicimg{
        width: 50%;
    }
    */
    
    .topic-title{
        font-size: 12px;
        line-height: 20px;
      
    }
    .topics-container{
        gap: 50px;
      
    }
    /* .container2{
        margin: 2rem 0;
        padding: 2rem 0;
    } */
}

@media screen and (max-width:  280px){
    

    .topic-card{
        width: 85%;
    }
    .topic-img{
        width: 100%;
    }

}