.backdropwelcome{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(120, 120, 120, 0.7);
    display: flex;
    /* align-items: center; */
    justify-content: center;
    z-index: 10000;
    font-family: var(--font-poppins);
}

.welcomepopup{
    padding: 25px;
    width: 40%;
    /* height: 60vh; */
    /* height: 500px; */
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    gap: 25px;
    text-align: center;
    border-radius: 20px;
    margin: auto;
    z-index: 1000;
    /* position: absolute;
    top: 25%;
    left: 25%; */
    
}

.welcomebtn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    background: var(--orange);
    border-radius: 100px;
    border: none;
    /* width: 180px;
    height: 50px; */
    font-style: normal;
    font-family: var(--font-poppins);
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
    cursor:pointer;
    
}

@media only screen and (max-width:912px)

{
    .welcomepopup > img{
        height: auto;
        width: 150px;
    }

    .welcomebtn{
       
        padding: 10px 15px;
        
    font-size: 16px;

    }
    .welcomepopup p{
        font-size: 14px;
    }
    .welcomepopup{
        width: 80%;
    }
}