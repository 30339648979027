.the-fascinating-world4 {
    margin: 0;
    font-size: 40px;
    padding: 0 10px;
  }
  .article-title {
    align-self: stretch;
    position: relative;
    line-height: 128.9%;
    font-weight: 500;
    font-family: var(--font-poppins);
  }
  .science {
    position: relative;
  }
  .category {
    border-radius: var(--br-base);
    background-color: grey;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    align-items: center;
    justify-content: center;
  }

  .minute-read {
    position: relative;
    display: inline-block;
    width: auto;
    flex-shrink: 0;
  }
  .time-read {
    width: 143px;
    display: flex;
    flex-direction: row;
    padding: 0px var(--padding-8xs);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    gap: var(--gap-8xs);
    color: var(--black);
  }
  .article-info-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: var(--gap-35xl);
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--white);
    margin-bottom: 10px;
  }
  .intro-main-image-icon {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    object-fit: cover;
    background-position: top !important;
    height: 360px;
  }
  .intro-content {
    align-self: stretch;
    position: relative;
    line-height: 32px;
  }
  .unknown-to-most {
    line-height: 32px;
    color: var(--black);

  }
  .dna {
    line-height: 35px;
  }
  .a12 {
    color: var(--color-mediumslateblue);
  }
  .intro-content1 {
    align-self: stretch;
    position: relative;
  }
  .intro-content-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 0px var(--padding-xl);
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    text-align: left;
  }
  .intro-box {
    align-self: stretch;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
    font-size: var(--bookerly-reading-desktop-size);
  }
  .article-header {
    align-self: stretch;
    background-color: #ffe68b;
    display: flex;
    flex-direction: column;
    padding: 15px 0 ;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-align: center;
    font-size: var(--font-size-29xl);
    color: var(--black);
    font-family: var(--bookerly-reading-desktop);
    width: 100%;
    margin-top: 10px ;
  }
  
  @media screen and (max-width: 420px) {
    .the-fascinating-world4 {
        font-size: 24px;
    }
  


    .science {
      font-size: var(--font-size-xs);
    }
  
    .vuesaxbulkclock-icon1 {
      width: 20px;
      height: 15px;
      transform: rotate(0deg);
    }
  
    .minute-read {
      font-size: var(--font-size-xs);
    }
  
    .article-info-box {
      gap: var(--gap-l);
      align-items: center;
      justify-content: flex-start;
    }
  .article-title{
    font-size: 24px;
  }
    .intro-main-image-icon {
      align-self: stretch;
      width: auto;
      height: 200px;
      background-position: center;
      object-fit: cover;
    }
  
    .intro-content {
      font-size: var(--font-size-base);
      line-height: 30px;
    }
  
    .intro-content1 {
      font-size: var(--font-size-base);
      line-height: 30px;
    }
  
    .intro-box {
      gap: var(--gap-3xs);
    }
  }
  