.application-img-1-1-icon {
    position: relative;
    width: 250px;
    height: 137px;
    object-fit: cover;
  }
  .application-img-box {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .application-title{
    /* align-self: stretch; */
    position: relative;
    font-size: var(--font-size-lg);
    line-height: 30px;
    font-family: var(--bookerly-reading-desktop);
    color: var(--black);
    text-align: center;
    font-size: 20px;
    background-color: var(--yellow);
    padding: 3px 10px;
  }

 
  .application-point-box {
    align-self: stretch;
    position: relative;
    line-height: 26.66px;
  }
  .application-content {
    align-self: stretch;
    background-color: var(--white);
    box-shadow: 4px 4px 15px -70px rgba(0, 0, 0, 0.1);
    border: 0.5px solid rgba(0, 0, 0, 0.15);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }
  .outcome-content,
  .outcome {
    align-self: stretch;
    position: relative;

  }
  .outcome {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
  }
  .outcome-content {
    line-height: 25px;
    font-family: var(--bookerly-reading-desktop);
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .application-box,
  .outcome-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .outcome-box {
    align-self: stretch;
    background-color: var(--yellow);
    padding: var(--padding-mini) var(--padding-3xs);
    justify-content: center;
    gap: 5px;
    text-align: center;
    font-size: var(--font-size-base);
    color: var(--black);
    font-family: var(--font-poppins);
  }

  .superscript-link1{
    color: black;
  }

  .application-box {
    width: 420px;
    overflow: hidden;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--font-inherit);
    color: var(--text-gray);
    font-family: var(--bookerly-reading-desktop);
  }

  .application-points{
    font-size: 16px;
    line-height: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .application-points p,.outcome-content p{
    font-size: 18px;
    line-height: 30px;
  }

  .bold-point{
    font-family: var(--bookerly-reading-desktop-bold);
    font-size: 18px;
  }
  .normal-point{
    line-height: 32px;
    font-size: 18px;
  }
  @media screen and (max-width: 420px) {
    .application-title {
      font-size: var(--font-size-base);
    }
    .application-point-box{
      font-size: var(--font-size-sm);
    }
    .application-box {
      align-self: stretch;
      width: auto;
    }
    .application-points p,.outcome-content p{
      font-size: 16px;
      line-height: 28px;
    }
  
  }
  