.testimonial{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1.2rem;
    margin-top: 1rem;
    box-sizing: border-box;
    border: 1 solid gray;
}
.testimonial h1{
    font-size: var(--font-size-xl);
    font-family: var(--font-poppins);
    color: var(--black);
    font-weight: bold;
}
.testimonial-container{
    display: flex;
    width: 100%;
    height: 80%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: -20px;
    margin: 2rem 0;
    
}

.testimonial-img{
    width: 40%;
    height: 100%;
    background: url(/public/Assets/images/testimonial-img.webp);
    background-size: cover;
    position: relative;
    margin-right: -20%;
    z-index: 10;
}

.testimonial-content{
    width :70%;
    height: 100%; 
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    background-color: var(--green);
    padding: 0 1.2rem;
}

.testimonial-content h1{
    font-size: 1.8rem;
    font-family: var(--font-poppins);
    color: var(--white);
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 1rem;
}
.testimonial-content p{
    color: var(--white);
}

.rating{
    width: 40%;
}

.content-box{
    background: transparent;
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    margin-left: 10%;
}

.testimonial-name{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
}
@media screen and (max-width: 912px){
    .testimonial-name{
        align-items: center;
    }
    .testimonial{
        height: auto;
    }
    .testimonial-container{
        flex-direction: column-reverse;
        gap: 20px;
        height: aut0;
    }

    .testimonial-content{
        align-items: center;
        width: 85%;
        padding: 2rem;
    }
    .content-box{
        align-items: center;
        margin-left: 0;
    }
    .testimonial-content h1{
        text-align: center;

    }
    .testimonial-img{
        display: none;
    }
}

@media screen and (max-width: 767px){
    .testimonial h1{
        font-size: 1.5rem;
    }
    .testimonial-container{
        height: 100%;
    }
    .testimonial-content h1{
        font-size: 1.2rem;
    }
    .testimonial-content p{
        font-size: 1rem;
    }
    .rating{
        width: 80%;
    }
    
}

@media screen and (max-width: 280px){
    .testimonial h1{
        font-size: 1.2rem;
    }

    .rating{
        width: 100%;
    }
}