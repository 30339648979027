
.Maxbeamsong{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background-color: var(--white);
    
}

.songcard{
    width: 450px;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 20px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 10vh;
}

.songcard img{
    width: 100%;
    height: 230px;
}

.songcard span{
    font-size: 24px;
    font-family: var(--font-poppins);
    text-align: center;
}

.scardbanner{
    height: 50%;
    width: auto;
}

.scardcontrol{
    width: 70%;
    height: auto;
}

.scardtracker{
    width: 70%;
    height: auto;
}

.rhap_container{
    border-radius: 8px;
    width: 90% !important;
    margin: 0 auto  !important;
}

.rhap_container svg{
    color: var(--orange);
}

.rhap_main{
    gap: 15px;
}

.rhap_main-controls{
    margin-right: 30px;
}


@media screen and (max-width : 767px) {
    .rhap_container{
        width: 100% !important;
        margin-bottom: 20px !important;
    }
    .rhap_container svg{
        color: var(--orange);
    }
    .songcard img{
        width: 100%;
        height: 150px;
    }
    
    .songcard span{
        font-size: 16px;
        font-family: var(--font-poppins);
        text-align: center;
    }

    .songcard{
        padding: 15px;
    }
    .rhap_main-controls{
        margin-right: 0px;
    }
    



    
    
}