.trending-card{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    cursor: pointer;
}


.trending-card-img img{
    width: 100%;
    border-radius: 12px;
}

/* #favHeart{
    display: none;
}

#heartImg{
    transition:all 300ms ease-in-out
}

#heartImg:hover{
    opacity: 0.5;
}

/* Add this CSS to your TrendingCard.css file */

  
#favHeart:hover ~ #heartImg{
    opacity: 0.5;
} 
.trending-card-wrapper{
    position: relative;
    width: 85%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

#hoverElement:hover ~ #favHeart{
    display: flex; /* Change the text color to red when element1 is hovered */
  }
.trending-card-title{
    font-family: var(--font-poppins);
    font-size: 18px;
    padding: 0;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.favourite-icon1 {
    position: absolute;
    top: 5%; 
    right: 5%;/* Adjust the position as needed * /* Adjust the position as needed */
    z-index: 99;
    width: 30px;
    height:30px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* Adjust the z-index to ensure it's above other content */
  }
  .heart-icon1{
    width: 25px;
    height: 25px;
  }

.trending-card-desc{
    font-family: var(--font-mulish);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: none;
    color: #667085;
}
.trending-category{
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 10px;
    border-radius: 100px;
    font-family: var(--font-mulish);
    font-weight: 500;
    font-size: 14px;
}

@media screen and (max-width:912px) {
    .trending-card,.hidden-card{
        width: 100%;
        justify-content: space-evenly;
    }
    .trending-card-title{
        font-size: 16px;
        padding: 0 5px;
    }
    
}

.hide{
    /* opacity: 0.5; */
    cursor: not-allowed;
    filter: blur(5px);
    opacity: 0.7;
   /* -webkit-filter: blur(8px); */
}

.hidden-title{
    font-family: var(--font-mulish);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

}
.hidden-card{
    cursor: not-allowed;
}
.hidden-card> .trending-category{
    display: none;
}

@media screen and (max-width:767px){
    .trending-content-container{
        flex-direction: column;
        align-items: center;
    }
    .trending-container{
        padding: 20px 20px 0;
    }
    .trending-card,.hidden-card{
        width: 100%;
    }
    .favourite-icon1 {
        position: absolute;
        top: 7%; 
        right: 15%;
        width: 25px;
        height: 25px;

      }

      .heart-icon1{
        width: 20px;
        height: 20px;
      }
      
    .trending-box{
        font-size: 18px;
    }
    .trending-card-title{
        font-size: 16px;
    }
    .trending-category{
        font-size: 12px;
        padding: 5px 10px;
    }
    .trending-card-wrapper{
        position: relative;
        width: 100%;
    }
}

   
