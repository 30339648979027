.beams-header-container1{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    background: url(/public/Assets/images/curiousityland-bg.webp);
    padding: 40px;
    text-align: center;
    flex-direction: column;
    background-position: top;
    background-size: cover;
    object-fit: cover;
    
}
.beams-header-container1 h1{
    font-size: 48px;
    font-family: var(--font-steva);
    font-weight: normal;
    margin-top: 15px;
    color: var(--white);
    color: var(--black);
    background-color: var(--white);
    padding: 0 10px;
    margin: 30px 0;
    box-shadow: var(--effect-1);
}

.beams-section{
    width: 100%;
    background-color: var(--white);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.beams-content-container{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    gap: 30px;
}

.low-box,.trending-box{
    font-family: var(--font-poppins);
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1.5px solid var(--orange);
}

.trending-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 60px;
    padding-bottom: 20px;
    /* padding: 70px 0; */
    width: 100%;
}

.trending-content-container{
    padding-top: 30px;
    display: grid;
    column-gap: 100px;
    row-gap: 60px;
    grid-template-columns: repeat(3, 1fr)
}
.beams-hero{
    width: 25%;
    margin-bottom: -20%;
}

.low-container{
  display: flex;
  align-items: flex-start;
  gap: 30px;
  padding: 0 60px;
  flex-direction: column;
}
@media screen and (max-width: 912px) {
    .beams-header-container1 h1{
        font-size: 40px;
        line-height: 40px;
        margin: 0;
        margin-top: 50px;
       
    }
    .beams-header-container1{
        padding: 50px;
    }
    .beams-hero{
        width: 30%;
        margin-bottom: -35%;
    }
    .trending-content-container{
        padding-top: 30px;
        display: grid;
        column-gap: 100px;
        row-gap: 60px;
        grid-template-columns: repeat(2, 1fr)
      
    }
    
}
@media screen and (max-width: 767px) {
    .beams-header-container1 h1{
        font-size: 24px;
        line-height: 30px;
        margin: 0;
        margin-top: 30px;
        
    }
    .trending-content-container{
        padding-top: 30px;
        display: grid;
        column-gap: 100px;
        row-gap: 80px;
        grid-template-columns: repeat(1, 1fr)
    }
    .beams-content-container{
        padding-top: 10px;
    }
    .beams-header-container1{
        padding: 30px 15px;
    }
    .low-container{
            padding: 20px;
            gap: 30px;
    }
  
    .beams-content-container{
        gap: 15px;
    }
    .beams-section{
        gap: 0px;
    }
    

    
}