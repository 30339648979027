.popupnotes{
    width: 60%;
    height: 550px;
    background-color: white;
    border-radius: 30px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    flex-direction: column;
    font-family: var(--font-poppins);
    z-index: 1001;
}

.Notepopheader{
    /* height: 120px; */
    padding: 15px 30px;
    background-color: #F7CD61;
    border-radius: 30px 30px 0px 0px; 
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    gap: 10px;
}

.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(120, 120, 120, 0.8);
    display: flex;
    align-items: center;    
    justify-content: center;
    z-index: 100;
}
.Notepopheader span{
    font-size: 18px;
}

.Notepopheader h2{
    margin-top: 10px;
    font-size: 24px;
    text-align: center;
}

.notes {
    background-attachment: local;
    /* background-image:
        linear-gradient(to right, white 15px, transparent 15px),
        linear-gradient(to left, white 15px, transparent 15px),
        repeating-linear-gradient(white, white 22px, #ccc 22px, #ccc 23px, white 22px); */
    line-height: 31px;
    padding: 8px 10px;
    width: 100%;
    height: 65%;
    outline: none;
    border: none;
    resize: none;
    font-size: 20px;
}



.popupnotes i{
    text-align: left;
    margin-left: 20px;
    font-size: 14px;
    font-family: var(--font-mulish);
    
}

.notepopcntrl{
    margin: 20px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
}

.popupcannsave{
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    gap: 30px;
}

.notespopupsavebtn{
    padding: 10px 30px 10px 30px;
    color: white;
    border-radius: 20px;
    background-color: var(--orange);

}

.notepopcntrl{
    cursor: pointer;
}

.notespopupdate{
    text-align: center;
}

@media screen and (max-width:767px) {
    .popupnotes{
        width: 85%;
        height: 90%;
    }
    .Notepopheader h2{
        font-size: 16px;
        margin-bottom: 10px;
    }
    .notepopcntrl{
        flex-direction: column;
    }
    .notepopcntrl > span{
        display: none;
    }
    .notepopcntrl{
        margin: 0;
        margin-top: 20px;
        /* padding-bottom: 10px; */
        gap: 40px ;
        padding: 0 0 10px;
        position: absolute;
        bottom: 0;
    }
    .popupnotes i{
        display: none;
    }
    .popupcannsave{
        width: 100%;
        justify-content: space-evenly;
        margin-bottom: 10px;
    }
}
