.Redirectionpage{
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.Redirectionpage h1{
    text-align: center;
}