.maxbeamcardcont{
    display: flex;
    width: 300px;
    background-color: white;
    box-shadow: 0px 20px 60px rgba(128, 144, 155, 0.2);
    border-radius: 20px;
    margin: 10px;
    position: relative;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 20px;
    margin-bottom: 30px;
}

.maxbeamdata > button{
    background: #F7CD61;
    border-radius: 30px;
    border: none;
    font-weight: 600;
    /* font-size: 18px; */
    cursor: pointer;
    padding: 10px;
}


.maxbeamdata{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 10px;
    gap: 5px;

}

.maxcardimg{
    
    width: 100%;
}
.maxcardimg img{
    height: 150px;
    width: 100%;
    border-radius: 10px;
}



.opdpitems{
    background-color: var(--white);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 3%;
    right: 3%;
}
.opdpitems svg{

    width: 20px;
    height: 20px;
    
}
.maxbeamdata h3{
    font-size: 18px;
    font-weight: 400;
}
.favcard3dots{
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
}

.favcarddrop{
    height: 110px;
    justify-content: space-evenly;
}

@media screen and (max-width:767px) {
    .maxbeamcardcont{
        width: 80%;
      
    }
    .maxcardimg img{
        height: 20%;
    }
    .maxbeamdata h3{
        font-size: 16px;
        font-weight: 400;
    }
    .maxbeamdata{
        padding: 0 10px;
        gap: 0;
    }
    .maxbeamdata > button{
        width: 100px;
        height: 30px;
        font-size: 12px;
    }
}