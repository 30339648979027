.favouritesnewcardpage{
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
}

.favouritesnewcardpage > input{
    transform: scale(1.5);
    margin-right: 20px;
    margin-top: 20px;
}

.favnewinnercont{
    width: 100%;
    border: 2px solid #F7CD61;
    border-radius: 20px;
    padding: 10px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
}

.note-content > p{
    width: 300px;
    font-size: 16px;
    line-height: 30px;
    
}
.beamname{
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}
.note-content{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}


.favnewinnercontdat{
    margin-top: 10px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.notescardoptions{
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 150px;
    background-color: white;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    /* display: none; */
}

.notescardoptionsinner{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 5px;
    border-radius: 10px;
}

.notescardoptionsinner:hover{
    background-color: rgb(243, 243, 243)
}

.notescardoptionsinner span{
    margin-left: 15px;
}

.notescard3dots{
    cursor: pointer;
}



