.application-header {
    align-self: stretch;
    position: relative;
    letter-spacing: 0.08px;
    line-height: 30px;
    text-transform: uppercase;
    font-size: 24px;

  }
  .application-header-content {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    line-height: 35px;
    color: var(--text-gray);
    text-transform: none;
  }
  .application-header-box {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-xl);
  }

.no-header, .no-content{
  display: none;
  padding: 0;
}
 
  
  .application-box-container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding-top: var(--padding-21xl);
    align-items: center;
    justify-content: center;
    gap: var(--gap-81xl);
    text-align: left;
    font-size: var(--font-inherit);
    color: var(--text-gray);
  }
  .applications {
    align-self: stretch;
    background-color: var(--white);
    /* box-shadow: 1px 4px 15px 10px rgba(0, 0, 0, 0.1); */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* padding: var(--padding-21xl) var(--padding-11xl); */
    align-items: center;
    justify-content: center;
    gap: var(--gap-xl);
    text-align: center;
    font-size: var(--font-size-5xl);
    color: var(--black);
    font-family: var(--bookerly-reading-desktop);
  }

  .no-padding{
    padding: 0;
  }
  
  @media screen and (max-width: 420px) {
    .application-header {
      font-size: 18px;
      line-height: 30px;
    }
  
    .application-header-content {
      line-height: 25px;
      font-size: 16px;

    }

    .applications{
      padding: 20px;
    }
  
  }
  