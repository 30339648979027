.brain-img-1-icon,
.bulb-img-1-icon {
 
  width: 20%;
  height: auto;
  object-fit: cover;
}

/* .image-grp {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 100px;
  
} */

.image-grp{
  display: none;
}

.introducing {
  font-weight: 500;
  /* display: inline-block; */
  width: 100%;
  text-align: center;
  font-size: var(--font-size-xl);
}

.beams-wrapper {
  border-radius: var(--br-base);
  background-color: var(--yellow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 64px;
  color: var(--black);
  font-family: var(--font-steva);
  padding: 5px 20px;
  font-weight: 500;
}
.beams-content {
  position: relative;
  font-size: 24px;
  line-height: 35px;
  font-family: var(--font-mulish);
  text-align: left;
  width: 80%;
}
.book-1-icon {
  width: 30%;
  object-fit: cover;
}
.introducing-beams {
  position: relative;
  /* background: radial-gradient(50% 50%at 50% 40%, #f7cd61, #fffffe 47.45%); */
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  text-align: left;
  font-size: var(--font-size-1xl);
  color: var(--black);
  font-family: var(--font-poppins);
}

.intro-beams-wrapper{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 600px) {
    .beams-content {
        font-size: 16px;
        line-height: 25px;
        text-align: left;
      }

      .beams-wrapper {
        font-size: 32px;
        padding: 10px;
      }
.brain-img-1-icon,
.bulb-img-1-icon {
 
  width: 30%;
  height: auto;
  
}
.image-grp{
    gap: 140px;
}
.book-1-icon {
    width: 50%;
    object-fit: cover;
  }
  .introducing-beams{
    gap: 50px;
    height: auto;
    padding: 2rem 0;
   /* background: radial-gradient(60% 40%at 50% 28%, #f7cd61, #fffffe 47.45%); */


  }
}
