.LibraryPage{
    background-color: var(--white);
    width: 100%;
    padding: 20px;
    /* margin-top: 90px; */
    font-family: var(--font-poppins);
}

.libraryoption{
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 100px;
    background-color: var(--white);
    align-items: center;
    margin-top: 8vh;
}

.libraryopitem{
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.libraryopitem span{
    font-size: 18px;
    margin-left: 10px;
    margin-right: 10px;
    color: #9F9F9F;

}

.lbopic{
    color: #9F9F9F;
    height: 35px;
    width: 35px;
}

.opandfildivider{
    margin-top: 20px;
    width: 100%;
    height: 1.4px;
    background-color: #dfdfdf;
}

.filteroption{ 
    background-color: var(--white);
    
    display: flex;
    padding-left: 100px;
    padding-right: 100px;
    margin: 30px 0 10px;
    justify-content: flex-end;
}

.filteroption select, .filteroption option{
    margin-right: 30px;
    border: none;
    font-size: 18px;
    padding: 10px;
    padding-right: 30px;
    border-radius: 20px;
    outline: none;
    box-shadow: var(--effect-1);
    position: relative;
    /* -moz-appearance:none;
    -webkit-appearance:none;  */
    /* appearance:none; */
    /* width: 300px; */
}

.filteroption  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

.arrowselectposition{
    position: absolute;
    top: 13px;
    right: 45px;
    z-index: 50;
}

.hightlightitem{
    padding-left: 100px;
    padding-right: 100px;
    margin-top: 60px;
}

.hightlightitemheading{
    display: flex;
    justify-content: space-between;
}

.highlightitemicon{
    font-size: 20px;
    margin-left: 50px;
    cursor: pointer;

   
}

.hightlightitemoptions > input{
    height: 17px;
    width: 17px;
    cursor: pointer;
}

.hightlightitemheading > span{
    font-size: 32px;
}

.highlightdata{
    padding-top: 20px;
    margin-left:   30px;
    margin-right: 30px;
    background-color: var(--white);
  
    
}

.highlightdata > img{
    margin-top: 30px;
}

.highlightcomponent{
    display: flex;
    margin-top: 14px;
    margin-bottom: 24px;
    margin-left: 20px;
    margin-Right: 20px;
}

@media only screen and (max-width:450px) {
    .highlightcomponent{
        margin-bottom: 35px;
    }
}

.highlightcomponent input{
    margin-right: 10px;
    transform: scale(1.5);
}

.highlightcomponentinner{
    background-color: #FADF9C;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 15px;
    width: 100%;
    /* max-width: 500px; */
}

.highlightcomponentinner > span{
    font-weight: 600;
    margin-bottom: 10px;
}

.highlightcomponentinner  strong{
    cursor: pointer;
}

.fwrap{

    width: 100%;
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); */
    /* justify-items: start; */
    display: flex;
    flex-direction: row;
    align-items: flex-start; 
    justify-content: flex-start;
    gap: 10%;
    min-height: 55vh;

    flex-wrap: wrap;
}

.gridwrapper{
    /* background-color: #000000; */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.highlightcomponentinner2{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.highlightscardoptions{
        width: 150px;
        background-color: white;
        position: absolute;
        right: -30px;
        bottom: 30px;
        height: 60px;
        border-radius: 20px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.highlightscardoptionsinner{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding: 10px;
    cursor: pointer;
}

.highlightscardoptionsinner:hover{
    background-color: rgb(246, 246, 246);
    
    border-radius: 20px;
}

.pagination{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.highlights3dots{
    position: absolute;
    right: 0px;
    /* top: 5px; */
    bottom: 0px;
}



@media only screen and (max-width:1193px)
{
    .libraryoption{
        width: 100%;
    }

}

@media only screen and (max-width:900px)
{
    .libraryoption{
        width: 100%;
    }
}

@media only screen and (max-width:637px)
{
    .filteroption{
        padding-right: 0px;
    }

    .highlightdata{
        margin-left: 0px;
        margin-right: 0px;
       
    }

    .libraryopitem span{
        font-size: 16px;
        margin-left: 2px;
    }
}

@media only screen and (max-width:623px) {
    .libraryoption{
        padding-left: 10px;
        padding-right: 10px;
    }

    .filteroption{
        padding-left: 0px;
    }
}


@media only screen and (max-width:767px) {
    .nodatadiv > img{
        width: 270px;
        height: auto;
    }

    .nodatadiv > h2{
        text-align: center;
        font-size: 18px !important;
    }

    .nodatadiv > p{
        text-align: center;
        font-size: 16px !important;
        padding: 30px;

    }
    @media screen and (max-width:767px){
        .LibraryPage{
            padding: 20px 10px;
        }
    }
}

@media only screen and (max-width:912px) {
  .fwrap{
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
}